<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員等級列表
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{
                isShow
                  ? "查看會員等級"
                  : !isEditing
                  ? "新增會員等級"
                  : "編輯會員等級"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">
            {{
              isShow
                ? "查看會員等級"
                : !isEditing
                ? "新增會員等級"
                : "編輯會員等級"
            }}
          </h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <section class="row mb-8">
          <div class="col-12 col-xl-9">
            <!-- -->
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              label="等級名稱"
              label-for="name"
            >
              <b-form-input
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.name"
                :state="v$.form.name.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.name.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <!-- -->
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              label-for="order"
            >
              <template #label>
                等級排序
                <i
                  role="button"
                  class="fa fa-question-circle s-text-primary text-black-50"
                  aria-hidden="true"
                  id="tooltip-for-order"
                ></i>
                <b-tooltip target="tooltip-for-order" triggers="hover" placement="right">
                  數字越大，等級越高
                </b-tooltip>
              </template>
              <template #default>
                <b-form-input
                  id="order"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  v-model="form.order"
                  :state="v$.form.order.$error ? false : null"
                  :disabled="isShow && !isEditing"
                ></b-form-input>
                <b-form-invalid-feedback :state="!v$.form.order.$error"
                  >此欄位為必填</b-form-invalid-feedback
                >
              </template>
            </b-form-group>
            <!-- -->
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              label="等級代碼"
              label-for="code"
            >
              <b-form-input
                id="code"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.code"
                :state="v$.form.code.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.code.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <!-- -->
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              label="等級說明"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.description"
                :state="v$.form.description.$error ? false : null"
                :disabled="isShow"
                row="6"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="!v$.form.description.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              label="是否啟用"
              label-for="is_enabled"
            >
              <b-form-checkbox
                id="is_enabled"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.is_enabled"
                :disabled="isShow"
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              label="有效期間"
            >
              <div
                class="
                  d-flex
                  flex-column flex-xl-row
                  align-items-start align-items-xl-center
                "
              >
                <datepicker
                  placeholder="請選擇日期"
                  v-model="start_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="isShow"
                  :disabledDates="disabledDates"
                  clear-button
                ></datepicker>
                <span class="mx-2">~</span>
                <datepicker
                  placeholder="請選擇日期"
                  v-model="end_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="isShow"
                  :disabledDates="disabledDates"
                  clear-button
                ></datepicker>
              </div>
              <b-form-invalid-feedback
                :state="!v$.start_at.beforeEndAt.$invalid"
              >
                結束時間必須大於起始時間
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
            >
              <template #label>
                等級圖片
                <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：280px x 280px</p>
              </template>
              <template #default>
                <li
                  class="s-image-list-item s-draggable"
                  v-if="image.logo || form.logo_url"
                >
                  <img class="s-cropped-image" :src="image.logo || form.logo_url" />
                  <button
                    v-if="!isShow"
                    class="btn btn-danger s-delete-btn"
                    type="button"
                    @click="deleteLogoImage()"
                  >
                    Ｘ
                  </button>
                </li>
                <li class="s-image-list-item" v-else>
                  <ImageSelector v-if="!isShow" @input="(croppedImage) => selectImage(croppedImage, 'logo')">
                    <template #trigger="{ openFileSelector }">
                      <button class="btn s-trigger-btn" @click="openFileSelector">
                        ＋ 加入照片
                      </button>
                    </template>
                  </ImageSelector>
                </li>
              </template>

            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
            >
              <template #label>
                會員卡底圖
                <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 880px</p>
              </template>
              <template #default>
                <li
                  class="s-image-list-item s-draggable"
                  v-if="image.cardPicture || form.card_picture_url"
                >
                  <img class="s-cropped-image" :src="image.cardPicture || form.card_picture_url" />
                  <button
                    v-if="!isShow"
                    class="btn btn-danger s-delete-btn"
                    type="button"
                    @click="deleteCardPictureImage()"
                  >
                    Ｘ
                  </button>
                </li>
                <li class="s-image-list-item" v-else>
                  <ImageSelector
                    v-if="!isShow"
                    @input="(croppedImage) => selectImage(croppedImage, 'cardPicture')"
                    :aspect-ratio="1400/880"
                  >
                    <template #trigger="{ openFileSelector }">
                      <button class="btn s-trigger-btn" @click="openFileSelector">
                        ＋ 加入照片
                      </button>
                    </template>
                  </ImageSelector>
                </li>
              </template>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >返回</b-button
        >
        <b-overlay
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          v-if="!isShow"
        >
          <b-button variant="success" @click="handleSubmit">
            {{ isEditing ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { isBefore, set, subDays } from "date-fns";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import customerLevelsApi from "@/apis/customer-levels";
import moment from "moment";
import imageApi from "@/apis/image";
import ImageSelector from "@/components/ImageSelector";
import { mapGetters } from "vuex";

export default {
  components: {
    Datepicker,
    ImageSelector,
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      zh,
      disabledDates: { to: subDays(new Date(), 1) },
      isShow: false,
      isEditing: false,
      isLoading: false,
      form: {
        name: null,
        order: 1,
        code: null,
        is_enabled: true,
        logo_url: null,
      },
      level: {},
      start_at: null,
      end_at: null,
      image: {
        logo: null,
        cardPicture: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        order: { required },
        description: { required },
        code: { required },
        logo_url: {},
      },
      start_at: {
        beforeEndAt: () => {
          if (!this.start_at || !this.end_at) {
            return true;
          }

          const startDate = set(new Date(this.start_at), {
            hours: 0,
            minutes: 0,
          });
          const endDate = set(new Date(this.end_at), {
            hours: 23,
            minutes: 59,
          });
          return isBefore(startDate, endDate);
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;

        if (this.$route.name === "CustomerLevelsEdit") {
          this.isEditing = true;

          const { data } = await customerLevelsApi.show(this.$route.params.id);

          this.level = data.data;
          this.form = data.data;
          this.form.is_enabled = !!this.level.is_enabled;
          this.start_at = this.level.start_at;
          this.end_at = this.level.end_at;
        }

        if (this.$route.name === "CustomerLevelsView") {
          this.isShow = true;
          const { data } = await customerLevelsApi.show(this.$route.params.id);

          this.level = data.data;
          this.form = data.data;
          this.form.is_enabled = !!this.level.is_enabled;
          this.start_at = this.level.start_at;
          this.end_at = this.level.end_at;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmit() {
      try {
        const result = await this.v$.$validate();
        if (!result) return;

        this.isLoading = true;

        if (this.image.logo) {
          this.form.logo_url = await this.uploadImage(this.image.logo);
          if (! this.form.logo_url) {
            return;
          }
        }
        if (this.image.cardPicture) {
          this.form.card_picture_url = await this.uploadImage(this.image.cardPicture);
          if (! this.form.card_picture_url) {
            return;
          }
        }

        if (this.isEditing === true) {
          await customerLevelsApi.update(this.level.id, {
            ...this.form,
            start_at: this.start_at
              ? moment(this.start_at).format("YYYY-MM-DD 00:00:00")
              : null,
            end_at: this.end_at
              ? moment(this.end_at).format("YYYY-MM-DD 23:59:59")
              : null,
            config: {},
          });

          this.$swal("更新成功", "", "success");
        } else {
          await customerLevelsApi.create({
            ...this.form,
            start_at: this.start_at
              ? moment(this.start_at).format("YYYY-MM-DD 00:00:00")
              : null,
            end_at: this.end_at
              ? moment(this.end_at).format("YYYY-MM-DD 23:59:59")
              : null,
            config: {
              dummy: "acmc",
            },
          });

          this.$swal("送出成功！", "", "success");
        }

        this.$router.push({
          name: "CustomerLevelsList",
          query: this.routeQuery,
        });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "", "error");
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.$router.push({
        name: "CustomerLevelsList",
        query: this.routeQuery,
      });
    },
    async uploadImage(image) {
      try {
        let response = await imageApi.uploadByBase64(image);
        return response.data.data.image.url;
      } catch (error) {
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    selectImage(image, key) {
      this.image[key] = image;
    },
    deleteLogoImage() {
      this.image.logo = null;
      this.form.logo_url = null;
    },
    deleteCardPictureImage() {
      this.image.cardPicture = null;
      this.form.card_picture_url = null;
    },
  },
};
</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: fit-content;
  height: 7rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.s-cropped-image {
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
